import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    meta: {
      title: "Kamnolom Pirešica",
    },
    component: () => import("@/views/Index.vue"),
  },
  {
    path: "/cenik",
    name: "Cenik",
    meta: {
      title: "Cenik",
    },
    component: () =>
        import("@/views/Prices.vue"),
  },
  {
    path: "/pravilnik-o-piskotkih",
    name: "Piškotki",
    meta: {
      title: "Piskotki",
    },
    component: () =>
        import("@/views/Cookies.vue"),
  },
  {
    path: "/reference",
    name: "Reference",
    meta: {
      title: "Reference",
    },
    component: () =>
        import("@/views/References.vue"),
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    meta: {
      title: "Kontakt",
    },
    component: () =>
        import("@/views/Contact.vue"),
  },
  {
    path: "/zaposlimo",
    name: "Zaposlimo",
    meta: {
      title: "Zaposlimo",
    },
    component: () =>
        import("@/views/Careers.vue"),
  },
  {
    path: "*",
    name: NotFound,
    meta: {
      title: "404",
    },
    component: () => import("@/views/NotFound.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach((to) => {
  if (to.meta) {
    document.title = "Kamnolom Pirešica"
  }
});

export default router;
