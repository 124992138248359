import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        darkMode: true,
        language: {name: 'SL'},
        languages: [{name: 'SLO', code: 'sl'}, {name: 'EN', code: 'en'}]
    },

    getters: {
        darkMode(state) {
            return state.darkMode;
        }
    },

    mutations: {
        DARK_MODE(state, data) {
            state.darkMode = data;
        },
        LANGUAGE_CHANGE(state, data) {
            state.language = data;
        }
    },

    actions: {
        toggleMode({ commit }, darkMode) {
            commit("DARK_MODE", darkMode);
        },
        changeLanguage({ commit }, language) {
            commit("LANGUAGE_CHANGE", language);
        }
    }
});
