import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueScrollReveal from 'vue-scroll-reveal';
import "./assets/scss/style.scss";
import store from "../store";
import 'bootstrap'; import 'bootstrap/dist/css/bootstrap.min.css';
import Vuex from "vuex";
import VueI18n from 'vue-i18n'
import en from '../lang/en-US.json'
import sl from '../lang/sl-SI.json'
import de from '../lang/de-DE.json'
import CookieLaw from 'vue-cookie-law'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)



Vue.use(VueI18n);
Vue.use(Vuex);
Vue.component('cookie-law', CookieLaw)
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: 'sl',
  fallbackLocale: 'sl',
  messages: {
    en, sl, de
  }
});

Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal',
  duration: 1500,
  scale: 1,
  distance: '100px',
  mobile: true
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
