<template>
  <div id="app" class="shane_tm_all_wrap">
    <router-view></router-view>
    <cookie-law theme="dark-lime" :buttonText="$t('common.accept')"
                v-if="this.$route.path !== '/pravilnik-o-piskotkih'">
      <div slot="message">
        {{ $t('common.cookies_text1') }}
        <router-link to="/pravilnik-o-piskotkih" style="color: #c5a47e;">{{ $t('common.cookies_text2') }}</router-link>
      </div>
    </cookie-law>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.classList.add("loaded");
  },
  destroyed() {
    document.body.classList.remove("loaded");
  },
};
</script>

<style lang="scss">

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

.Cookie--dark-lime .Cookie__button {
  background: #c5a47e !important;
  padding: 0.25em 1.125em;
  color: #fff;
  border-radius: 12px !important;
  border: 0;
  font-size: 1em;
}

.Cookie--dark-lime {
  background: #c5a47e;
  color: #fff;
  padding: 1.25em;
  opacity: 90%;

  @media only screen and (min-width: 768px) and (max-width: 1040px) {
    padding: 2px 2px;
    font-size: 15px;
    line-height: 20px;
  }

  @media only screen and (min-width: 100px) and (max-width: 768px) {
    padding: 2px 2px;
    font-size: 12px;
    line-height: 10px;
  }
}


::selection {
  background: #c5a47e;
  color: black;
  text-shadow: none;
}

::-moz-selection {
  background: #c5a47e;
  color: #EEE;
  text-shadow: none;
}

//.container {
//  @media only screen and (min-width: 768px) and (max-width: 1040px) {
//  }
//
//  @media only screen and (min-width: 100px) and (max-width: 768px) {
//    padding: 2px 2px;
//    font-size: 12px;
//    line-height: 10px;
//  }
//}

</style>
